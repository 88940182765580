import React from 'react';
import {Button} from 'antd';
import styled from 'styled-components';
import {withTheme} from '@rjsf/core';
import {Theme as AntDTheme} from '@rjsf/antd';

const RjsForm = withTheme(AntDTheme);

const schema = {
  title: 'Product',
  description: '-- Update your product information --',
  type: 'object',
  required: ['name', 'price'],
  properties: {
    name: {type: 'string', title: 'Name'},
    price: {
      type: 'number',
      title: 'Price',
      default: 100,
      // the following properties have bugs for antd theming
      // it works during the onSubmit callback, but no effect for UI
      minimum: 100,
      maximum: 1000,
      multipleOf: 100,
    },
    outOfStock: {type: 'boolean', title: 'Out-of-stock', default: false},
    // see more about the `format` property:
    // https://react-jsonschema-form.readthedocs.io/en/latest/usage/widgets/
    email: {type: 'string', title: 'Email', format: 'email'},
    birthday: {type: 'string', title: 'Birthday', format: 'date'},
    attachment: {type: 'string', title: 'Attachment', format: 'data-url'},
    // enum & enumNames
    category: {
      type: 'string',
      title: 'Category',
      enum: ['shoe', 'hat', 'shirt'],
      enumNames: ['SHOE', 'HAT', 'SHIRT'],
      default: 'shoe',
    },
    // multi-select is also supported
    options: {
      type: 'array',
      title: 'Options',
      items: {
        type: 'string',
        enum: ['FeatureA', 'FeatureB', 'FeatureC', 'FeatureD'],
      },
      uniqueItems: true,
    },
    // dynamic array with free text
    labels: {
      type: 'array',
      maxItems: 3,
      items: {
        type: 'string',
      },
    },
    // dependencies, when choose 'Yes', more fields are expanded
    invoiceType: {
      type: 'string',
      title: 'Invoice',
      enum: ['no', 'yes'],
      enumNames: ['No', 'Yes'],
      default: 'no',
    },
  },
  dependencies: {
    invoiceType: {
      oneOf: [
        {
          properties: {
            invoiceType: {
              enum: ['no'],
            },
          },
        },
        {
          properties: {
            invoiceType: {
              enum: ['yes'],
            },
            address: {
              type: 'string',
              title: 'Invoice Address',
            },
          },
          required: ['address'],
        },
      ],
    },
  },
};

const uiSchema = {
  attachment: {
    'ui:options': {accept: '.png'},
  },
};

function MyForm(props) {
  const {instance} = props;
  const [values, setValues] = React.useState(instance || {});
  const submitBtnRef = React.useRef();

  return (
    <Wrapper>
      <RjsForm
        schema={schema}
        uiSchema={uiSchema}
        formData={values}
        onChange={({formData}) => {
          console.log('changed formData', formData);
          setValues(formData);
        }}
        onSubmit={({formData}) => {
          console.log('submitted formData', formData);
        }}
        onError={(errors) => {
          console.log('errors', errors);
        }}>
        <div className="submit-buttons-bar">
          <Button
            type="primary"
            onClick={() => {
              submitBtnRef.current.click();
            }}>
            儲存
          </Button>

          <button type="submit" ref={submitBtnRef}></button>
        </div>
      </RjsForm>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 480px;
  padding: 20px;

  & .submit-buttons-bar {
    display: flex;
    justify-content: flex-end;

    & button[type='submit'] {
      display: none;
    }
  }
`;

export default MyForm;
